// third-party
import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";

const flattenSiteActionLogs = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.values?.taskType || "N/A",
    status: item?.values?.status || "N/A",
    submittedAt: item.createdAt || "N/A",
    completedAt: item.values?.completedAt || "N/A",
    updatedAt: item.updatedAt || "N/A",
    username: item.values?.user?.username || "N/A",
    taskId: item.values?.task || "N/A",
    scheduledAt: item?.values?.scheduledAt || "N/A",
    createdAt: item?.values?.createdAt,
    error: item?.values?.error || "N/A",
    arguments: item?.values?.arguments || "N/A",
  });
  return {
    data: data.logs?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

const flattenSiteActionOverview = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.task?.name || "N/A",
    status: item.status,
    submittedAt: item.createdAt || "N/A",
    scheduledAt: item.scheduledAt || "N/A",
    completedAt: item.completedAt || "N/A",
    updatedAt: item.task?.updatedAt || "N/A",
    username: item.user?.username || "N/A",
    taskId: item.task?.id || "N/A",
    error: item?.error ? item.error.split("\r\n")[0] : "N/A",
    arguments: item?.arguments || "N/A",
  });
  return {
    currentQueue: data.currentQueue?.rows.map(flattenData) || [],
    recentlyCompleted: data.recentlyCompleted?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  tools: [],
  devices: {
    count: 0,
    rows: [],
  },
  selectedTool: {},
  robotsWithCarts: {},
  siteActionOverview: {
    currentQueue: [],
    recentlyCompleted: [],
    latestUpdate: {
      status: "New",
      updatedAt: "",
    },
  },
  siteActionLogs: {
    logs: {
      count: 0,
      rows: [],
      usingTest: false,
    },
    latestUpdate: {
      updatedAt: "",
      status: "New",
    },
  },
  integrationMonitoringLogs: { count: 0, rows: [] },
  unassignedJobs: {
    count: 2,
    rows: [],
  },
};

const slice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getToolsSuccess(state, action) {
      state.tools = action.payload;
    },
    // SET SELECTED Tools
    getSelectedToolsSuccess(state, action) {
      state.selectedTool = action.payload;
    },
    getRobotsWithCartsSuccess(state, action) {
      state.robotsWithCarts = action.payload;
    },
    getSiteActionOverviewSuccess(state, action) {
      state.siteActionOverview = action.payload;
    },
    updateStatusSuccess(state, action) {
      state.siteActionOverview.latestUpdate.status = action.payload;
    },
    getSiteActionLogsSuccess(state, action) {
      state.siteActionLogs = action.payload;
    },
    getMerakiDevicesSuccess(state, action) {
      state.devices = action.payload;
    },
    getIntegrationMonitoringLogsSuccess(state, action) {
      state.integrationMonitoringLogs = action.payload;
    },
    getUnassignedJobsSuccess(state, action) {
      state.unassignedJobs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTools() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/tools`,
        {},
        {},
      );
      dispatch(slice.actions.getToolsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRobotsWithCarts({ site, searchFilter }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/cart/robot`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
          params: {
            ...searchFilter,
          },
        },
      );

      // flatten the carts data and adding "id" to pass in the grid table
      const processedRows = [];
      response.data?.rows?.forEach((robotItem, rIdx) => {
        robotItem?.carts?.forEach((cartItem, cIdx) => {
          processedRows.push({
            id: `${rIdx}-${cIdx}`,
            cart: cartItem,
            robot: robotItem.robot,
          });
        });
      });
      dispatch(
        slice.actions.getRobotsWithCartsSuccess({
          count: response?.data?.count,
          rows: processedRows,
        }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSiteActionLogs(
  site,
  environment,
  timestampRange,
  task = undefined,
  page,
  pageSize,
) {
  return async () => {
    const timestampRangeString =
      timestampRange[0] && timestampRange[1]
        ? `${new Date(timestampRange[0]).toISOString()},${new Date(timestampRange[1]).toISOString()}`
        : undefined;

    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/logs`,
      {
        headers: {
          "locus-agent-env": environment,
        },
        params: {
          site,
          timestampRange: timestampRangeString,
          group: false, // Todo maybe update
          page,
          pageSize,
          task,
        },
      },
    );

    const flattenedData = flattenSiteActionLogs(response.data.data);
    dispatch(slice.actions.getSiteActionLogsSuccess(flattenedData));
    return response;
  };
}

function buildStatusBody(
  site,
  user,
  taskType,
  taskId,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  let postBody = {
    scheduledAt: new Date(scheduledAt).toISOString(), // Ensure the date is in UTC format
    status,
    taskType,
    task: taskId,
    user: {
      username: user.username,
      id: user.id,
    },
    arguments: args,
    site: site.id,
  };
  return postBody;
}

async function getTaskId(taskName) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/agenttask/find`,
      {
        params: {
          name: taskName,
        },
      },
    );
    return response.data?.id || null;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function postStatusAgentCheck(
  site,
  user,
  environment,
  taskType,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  return async () => {
    try {
      const taskId = await getTaskId(taskType);

      const postBody = buildStatusBody(
        site,
        user,
        taskType,
        taskId,
        args,
        scheduledAt,
        status,
      );

      const responseAgenttask = await axios.post(
        `${process.env.REACT_APP_TOOLING_API}/tooling/status/agenttask`,
        postBody, // Post body goes here
        {
          headers: {
            "locus-agent-env": environment,
          },
        },
      );
      dispatch(slice.actions.updateStatusSuccess("New"));
      return responseAgenttask;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export function getSiteActionOverview(site, environment, group = false) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/overview`,
        {
          headers: {
            "locus-agent-env": environment,
          },
          params: {
            site: site,
            group: group,
          },
        },
      );
      const flattenedData = flattenSiteActionOverview(response.data.data);
      dispatch(slice.actions.getSiteActionOverviewSuccess(flattenedData));
      return flattenedData;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
    }
  };
}

export function getMerakiDevices({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedSiteId,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
        ...searchFilter,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevice/${selectedSiteId}`,
        { params },
        {},
      );
      dispatch(
        slice.actions.getMerakiDevicesSuccess({
          count: response.data?.count,
          rows: response.data?.data,
        }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMerakiDevice(context) {
  return async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevicelockstate`,
        context,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getIntegrationMonitoringLogs({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }

      params = { ...params, ...searchFilter };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
          params,
        },
        {},
      );
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(response.data),
      );
    } catch (error) {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess({
          count: 0,
          rows: [],
        }),
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearIntegrationMonitoringLogs() {
  return async () => {
    try {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(
          initialState.integrationMonitoringLogs,
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIntegrationMonitoringLogById({ selectedSite, logId }) {
  return async () => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog/${logId}`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
        },
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getUnassignedJobs({ site }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/warehouse/job/unassigned`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
        },
      );
      dispatch(
        slice.actions.getUnassignedJobsSuccess({
          count: response.data.length,
          rows: response.data,
        }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.getUnassignedJobsSuccess({
          count: 0,
          rows: [],
        }),
      );
    }
  };
}
